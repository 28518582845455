import {
  ArrayInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  required,
  useTranslate,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { Styles } from "./styles/source.css";
import { useEffect } from "react";
import { useForm } from "react-final-form";
import { clearStyleFromHtml } from "@/Tools/helpers";

type S3DepositSourceProps = {
  s3DepositConnection: string;
};

export function S3DepositSource({ s3DepositConnection }: S3DepositSourceProps) {
  const translate = useTranslate();
  const form = useForm();
  const sortable = { field: "name", order: "ASC" };
  const style = Styles();

  useEffect(() => {
    form.change("source.connection", s3DepositConnection);
  }, [form, s3DepositConnection]);

  return (
    <div className={style.sourceCSS}>
      <RichTextInput
        label={translate("resources.configuration.fields.source.description")}
        source="source.description"
        validate={[required()]}
        parse={clearStyleFromHtml}
      />
      <ReferenceInput
        label={translate("resources.configuration.fields.source.connection")}
        source="source.connection"
        reference="connection"
        defaultValue={s3DepositConnection}
      >
        <SelectInput optionText="name" value={s3DepositConnection} disabled />
      </ReferenceInput>
      <BooleanInput
        label={translate("resources.configuration.fields.source.enabled")}
        source="source.enabled"
        defaultValue={true}
      />
      <ReferenceInput
        label={translate("resources.configuration.fields.source.application")}
        source="source.application"
        reference="application"
        validate={[required()]}
        sort={sortable}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput
        label={translate("resources.configuration.fields.source.job")}
        source="source.job"
        parse={(value: any) => value}
      />
      <TextInput
        label={translate("resources.configuration.fields.source.information")}
        source="source.information"
        parse={(value: any) => value}
      />
      <ArrayInput
        label={translate("resources.configuration.fields.source.transformations")}
        source="source.transformations"
      >
        <SimpleFormIterator>
          <ReferenceInput
            source=""
            reference="transformationType"
            validate={[required()]}
            label="resources.configuration.fields.transformation"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
    </div>
  );
}
