import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "@/types";
import RichTextInput from "ra-input-rich-text";
import {
  TabbedForm,
  FormTab,
  TextInput,
  BooleanInput,
  required,
  Create,
  ReferenceInput,
  SelectInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  maxLength,
  useTranslate,
  FormDataConsumer,
} from "react-admin";
import API from "@aws-amplify/api-rest";
import { scheduleValidation } from "..";
import IntegrationTarget from "./IntegrationTarget";
import TargetDelay from "./TargetDelay";
import { useRedirectWithParams } from "@/Tools/hooks";
import { clearStyleFromHtml, toUpperCase, validateFilename } from "@/Tools/helpers";
import {
  DEFAULT_TRANSFERT_MODE,
  DEFAULT_TRANSFERT_TYPE,
  DEFAULT_S3_DEPOSIT_CONNECTION,
  DEFAULT_POLLING_TYPE,
} from "./constants";
import { S3DepositSource } from "@/Components/Models/Configuration/S3DepositSource";
import { StandardSource } from "@/Components/Models/Configuration/StandardSource";

export const CreateForm = (props: any) => {
  const [useS3Deposit, setS3Deposit] = useState(false);
  const s3DepositConnection = useRef("");
  const redirect = useRedirectWithParams(props.resource, [["from", "create"]]);
  const translate = useTranslate();
  const theme = useSelector((state: AppState) => (state.theme === "dark" ? "darkTheme" : ""));
  const sortable = { field: "name", order: "ASC" };

  useEffect(() => {
    API.get("api", `/connection`, {
      queryStringParameters: {
        filter: JSON.stringify({ name: DEFAULT_S3_DEPOSIT_CONNECTION }),
      },
    }).then((json) => {
      let s3Connection = json.Items[0];
      s3DepositConnection.current = s3Connection.id;
    });
  }, []);

  const formatIntegration = (integration: any) => {
    switch (integration.type) {
      case "WRITE_FILE":
        return {
          use_integration: integration.use_integration,
          type: integration.type,
          template: integration.template,
          parameters: {
            connection: integration.parameters!.connection,
            path: integration.parameters.path,
            file_name: integration.parameters.file_name,
            content_pattern: integration.parameters.content_pattern,
            use_target_connection: integration.parameters.use_target_connection,
          },
        };
      case "START_FLOW":
        return {
          use_integration: integration.use_integration,
          type: integration.type,
          parameters: {
            flow_id: integration.parameters!.flow_id,
          },
        };
      default:
        return integration;
    }
  };

  const cleanFields = (data: any) => {
    const useSourceTransformations = data.source.transformations ? true : false;
    const useSourceMoveAfter = data.source.transfert_type === "MOVE_AFTER" ? true : false;
    if (!useSourceTransformations) delete data["source"]["transformations"];
    if (!useSourceMoveAfter) delete data["source"]["transfert_path"];
    if (!data.source.use_s3_deposit) {
      return {
        ...data,
        source: {
          ...data.source,
          use_s3_deposit: false,
        },
        targets: data.targets.map((target: any) => {
          const useTargetTransformations = target.transformations ? true : false;
          const useIntegration = target.integration.use_integration ? true : false;
          if (!useTargetTransformations) delete target["transformations"];
          return {
            ...target,
            integration: {
              ...(useIntegration
                ? formatIntegration(target.integration)
                : { use_integration: false }),
            },
          };
        }),
      };
    }

    const emptyFilters = {
      unitType: "",
      unit: 0,
    };
    return {
      ...data,
      source: {
        ...data.source,
        use_s3_deposit: data.source.use_s3_deposit,
        connection: s3DepositConnection.current,
        path: "",
        regex: "",
        transfert_type: DEFAULT_TRANSFERT_TYPE,
        transfert_mode: DEFAULT_TRANSFERT_MODE,
        nb_files_per_transfert: "-1",
        polling: DEFAULT_POLLING_TYPE,
        filters_enabled: false,
        filters: emptyFilters,
      },
      targets: data.targets.map((target: any) => {
        const useTargetTransformations = target.transformations ? true : false;
        const useIntegration = target.integration.use_integration ? true : false;
        if (!useTargetTransformations) delete target["transformations"];
        return {
          ...target,
          integration: {
            ...(useIntegration
              ? formatIntegration(target.integration)
              : { use_integration: false }),
          },
        };
      }),
    };
  };

  const handleChange = (event: boolean) => {
    setS3Deposit(event);
  };

  return (
    <Create {...props} onSuccess={redirect} transform={cleanFields}>
      <TabbedForm warnWhenUnsavedChanges>
        <FormTab label="Summary">
          <TextInput source="name" validate={[required(), maxLength(4)]} parse={toUpperCase} />
          <TextInput source="code" validate={[required(), maxLength(6)]} parse={toUpperCase} />
          <RichTextInput source="description" validate={[required()]} parse={clearStyleFromHtml} />
          <TextInput source="requestor" validate={[maxLength(100)]} />
          <TextInput source="demand_number" validate={[maxLength(100)]} />
          <ReferenceInput source="criticity" reference="criticityType" validate={[required()]}>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <BooleanInput source="enabled" defaultValue={true} />
          <BooleanInput source="direct" defaultValue={false} />
        </FormTab>

        <FormTab label="Source">
          <BooleanInput
            source="source.use_s3_deposit"
            label={"Use S3 Deposit"}
            onChange={handleChange}
          />
          {useS3Deposit ? (
            <S3DepositSource s3DepositConnection={s3DepositConnection.current} />
          ) : (
            <StandardSource />
          )}
        </FormTab>

        <FormTab label="Target">
          <ArrayInput source="targets">
            <SimpleFormIterator className={`${theme} multiTargetConfig`}>
              <RichTextInput
                source="description"
                validate={[required()]}
                label={translate("resources.configuration.fields.target.description")}
              />
              <ReferenceInput
                source="connection"
                reference="connection"
                validate={[required()]}
                label={translate("resources.configuration.fields.target.connection")}
                sort={sortable}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
              <BooleanInput
                source="enabled"
                defaultValue={true}
                label={translate("resources.configuration.fields.target.enabled")}
              />
              <BooleanInput
                source="error_if_file_exists"
                defaultValue={false}
                label={translate("resources.configuration.fields.target.error_if_file_exists")}
              />
              <TextInput
                source="path"
                validate={[required()]}
                label={translate("resources.configuration.fields.target.path")}
              />
              <ReferenceInput
                source="application"
                reference="application"
                validate={[required()]}
                label={translate("resources.configuration.fields.target.application")}
                sort={sortable}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
              <TextInput
                label={translate("resources.configuration.fields.target.wisp_group")}
                source="wisp_group"
              />
              <TextInput
                source="job"
                label={translate("resources.configuration.fields.target.job")}
              />
              <TextInput
                source="information"
                label={translate("resources.configuration.fields.target.information")}
              />
              <ReferenceInput
                source="pattern_file_name"
                reference="fileNamePattern"
                validate={[required()]}
                label={translate("resources.configuration.fields.target.pattern_file_name")}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
              <FormDataConsumer>
                {({ formData, scopedFormData, getSource, ...rest }) =>
                  scopedFormData && scopedFormData.pattern_file_name === "TRIM" ? (
                    <TextInput
                      source={getSource?.("trim_character") || "targets[0].trim_character"}
                      validate={[required()]}
                      label={translate("resources.fileNamePattern.fields.trim_character")}
                    />
                  ) : null
                }
              </FormDataConsumer>
              <FormDataConsumer>
                {({ formData, scopedFormData, getSource, ...rest }) =>
                  scopedFormData &&
                  (scopedFormData.pattern_file_name === "RENAME" ||
                    scopedFormData.pattern_file_name === "RENAME_CHRONO" ||
                    scopedFormData.pattern_file_name === "RENAME_NOW") ? (
                    <TextInput
                      source={getSource?.("new_name") || "targets[0].new_name"}
                      validate={[required(), validateFilename]}
                      label={translate("resources.fileNamePattern.fields.new_name")}
                    />
                  ) : null
                }
              </FormDataConsumer>
              <NumberInput
                source="nb_files_per_transfert"
                defaultValue={-1}
                label={translate("resources.configuration.fields.target.nb_files_per_transfert")}
              />
              <TargetDelay
                source="delay"
                label={translate("resources.configuration.fields.target.delay")}
              />
              <FormDataConsumer>
                {({ formData, scopedFormData, getSource, ...rest }) =>
                  formData && formData.direct === false ? (
                    <TextInput
                      source={
                        getSource?.("schedule_expression") || "targets[0].schedule_expression"
                      }
                      validate={[required(), scheduleValidation]}
                      label={translate("resources.configuration.fields.source.schedule_expression")}
                    />
                  ) : null
                }
              </FormDataConsumer>

              <IntegrationTarget
                source="integration"
                label={translate("resources.configuration.fields.target.integration")}
              />
              <ArrayInput
                source="transformations"
                label={translate("resources.configuration.fields.target.transformations")}
              >
                <SimpleFormIterator>
                  <ReferenceInput
                    source=""
                    reference="transformationType"
                    validate={[required()]}
                    label="resources.configuration.fields.transformation"
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default CreateForm;
