import {
  ArrayInput,
  BooleanInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  required,
  useTranslate,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { ConditionalInput } from "@/Components/ConditionalForm";
import FiltersSource from "@/Components/Models/Configuration/FiltersSource";
import { scheduleValidation } from "..";
import { Styles } from "./styles/source.css";
import { clearStyleFromHtml } from "@/Tools/helpers";

export function StandardSource() {
  const translate = useTranslate();
  const sortable = { field: "name", order: "ASC" };
  const style = Styles();

  return (
    <div className={style.sourceCSS}>
      <RichTextInput
        label={translate("resources.configuration.fields.source.description")}
        source="source.description"
        validate={[required()]}
        parse={clearStyleFromHtml}
      />
      <ReferenceInput
        label={translate("resources.configuration.fields.source.connection")}
        source="source.connection"
        reference="connection"
        validate={[required()]}
        sort={sortable}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <BooleanInput
        label={translate("resources.configuration.fields.source.enabled")}
        source="source.enabled"
        defaultValue={true}
      />
      <TextInput
        label={translate("resources.configuration.fields.source.path")}
        source="source.path"
        validate={[required()]}
      />
      <ReferenceInput
        label={translate("resources.configuration.fields.source.application")}
        source="source.application"
        reference="application"
        validate={[required()]}
        sort={sortable}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput
        label={translate("resources.configuration.fields.source.wisp_group")}
        source="source.wisp_group"
      />
      <TextInput
        label={translate("resources.configuration.fields.source.job")}
        source="source.job"
        parse={(value: any) => value}
      />
      <TextInput
        label={translate("resources.configuration.fields.source.information")}
        source="source.information"
        parse={(value: any) => value}
      />
      <TextInput
        label={translate("resources.configuration.fields.source.regex")}
        source="source.regex"
        defaultValue={"*"}
      />
      <ReferenceInput
        label={translate("resources.configuration.fields.source.transfert_type")}
        source="source.transfert_type"
        reference="transfertType"
        validate={[required()]}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ConditionalInput validate={(values: any) => values.source.transfert_type === "MOVE_AFTER"}>
        <TextInput
          label={translate("resources.configuration.fields.source.transfert_path")}
          source="source.transfert_path"
          validate={[required()]}
        />
      </ConditionalInput>
      <ReferenceInput
        label={translate("resources.configuration.fields.source.transfert_mode")}
        source="source.transfert_mode"
        reference="transfertMode"
        validate={[required()]}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput
        abel={translate("resources.configuration.fields.source.nb_files_per_transfert")}
        source="source.nb_files_per_transfert"
        defaultValue={-1}
      />
      <ReferenceInput
        label={translate("resources.configuration.fields.source.polling")}
        source="source.polling"
        reference="pollingType"
        validate={[required()]}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ConditionalInput validate={(values: any) => values.source.polling === "AUTOMATIC"}>
        <TextInput
          source="source.schedule_expression"
          validate={[required(), scheduleValidation]}
          label={translate("resources.configuration.fields.source.schedule_expression")}
        />
      </ConditionalInput>
      <FiltersSource />
      <ArrayInput
        label={translate("resources.configuration.fields.source.transformations")}
        source="source.transformations"
      >
        <SimpleFormIterator>
          <ReferenceInput
            source=""
            reference="transformationType"
            validate={[required()]}
            label="resources.configuration.fields.transformation"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
    </div>
  );
}
